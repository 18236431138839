<template>
  <div
    class='map-chart'
    ref='chartdiv'
  ></div>
</template>

<script>
// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { useTheme, create, color, percent, Button, Sprite } from '@amcharts/amcharts4/core'
import { MapChart, projections, ZoomControl, MapPolygonSeries, HeatLegend } from '@amcharts/amcharts4/maps'
import am4geodataWorldLow from '@amcharts/amcharts4-geodata/worldLow'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'
import { mapGetters } from 'vuex'

useTheme(am4themesAnimated)

export default {
  name: 'map-chart',
  props: {
    remoteData: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data () {
    return {
      chart: null,
    }
  },
  computed: {
    ...mapGetters(['currentLocale']),
  },
  watch: {
    async remoteData (val) {
      if (this.chart) {
        await this.chart.dispose()
      }
      await this.$nextTick()
      this.setupChart()
    },
  },
  mounted () {
    this.setupChart()
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  },
  methods: {
    setupChart () {
      const chart = create(this.$refs.chartdiv, MapChart)
      chart.colors.list = [
        color('#e6e6e6'),
        color(this.$themes.primary),
      ]

      // Set map definition
      chart.geodata = am4geodataWorldLow

      // Set projection
      chart.projection = new projections.Miller()

      // Disable zooming
      // chart.seriesContainer.resizable = false
      chart.seriesContainer.draggable = true
      chart.zoomControl = new ZoomControl()
      chart.maxZoomLevel = 10
      chart.seriesContainer.events.disableType('doublehit')
      chart.chartContainer.background.events.disableType('doublehit')

      // Create map polygon series
      const polygonSeries = chart.series.push(new MapPolygonSeries())

      // Set min/max fill color for each area
      polygonSeries.heatRules.push({
        property: 'fill',
        target: polygonSeries.mapPolygons.template,
        min: chart.colors.getIndex(1).brighten(1),
        max: chart.colors.getIndex(1).brighten(-0.3),
      })

      // Make map load polygon data (state shapes and names) from GeoJSON
      polygonSeries.useGeodata = true

      // Set heatmap values for each state
      const chartData = []
      for (const c of this.remoteData) {
        const name = this.$t(c.name)
        chartData.push({ name: name, id: c.id, value: c.value })
      }
      polygonSeries.data = chartData.slice(0)
      const values = this.remoteData.map(d => d.value)

      // Set up heat legend
      const heatLegend = chart.createChild(HeatLegend)
      heatLegend.series = polygonSeries
      heatLegend.align = 'left'
      heatLegend.valign = 'bottom'
      heatLegend.width = percent(20)
      heatLegend.marginLeft = percent(5)
      heatLegend.marginBottom = percent(5)
      heatLegend.minValue = 0
      const maxValue = Math.max(...values)
      heatLegend.maxValue = Number.isNaN(maxValue) ? 0 : maxValue

      // Set up custom heat map legend labels using axis ranges
      const minRange = heatLegend.valueAxis.axisRanges.create()
      minRange.value = heatLegend.minValue
      minRange.label.text = '0'
      const maxRange = heatLegend.valueAxis.axisRanges.create()
      maxRange.value = heatLegend.maxValue
      maxRange.label.text = heatLegend.maxValue.toString()

      // Blank out internal heat legend value axis labels
      heatLegend.valueAxis.renderer.labels.template.adapter.add('text', function (labelText) {
        return ''
      })

      // Configure series tooltip
      const polygonTemplate = polygonSeries.mapPolygons.template
      polygonTemplate.tooltipText = '{name}: {value}'
      polygonTemplate.nonScalingStroke = true
      polygonTemplate.strokeWidth = 0.5

      // Create hover state and set alternative fill color
      const hs = polygonTemplate.states.create('hover')
      hs.properties.fill = color(this.$themes.primary)

      let north, south, east, west
      chart.events.on('ready', (ev) => {
        const countries = this.remoteData.slice(0)
        const countriesOnMap = []
        for (const c of countries) {
          const country = polygonSeries.getPolygonById(c.id)
          if (north === undefined || (country.north > north)) {
            north = country.north
          }
          if (south === undefined || (country.south < south)) {
            south = country.south
          }
          if (west === undefined || (country.west < west)) {
            west = country.west
          }
          if (east === undefined || (country.east > east)) {
            east = country.east
          }
          countriesOnMap.push(country)
        }

        // Pre-zoom
        chart.zoomToRectangle(north, east, south, west, null, true)
      })

      const homeButton = new Button()
      homeButton.events.on('hit', () => {
        chart.zoomToRectangle(north, east, south, west, null, true)
      })

      homeButton.icon = new Sprite()
      homeButton.padding(7, 5, 7, 5)
      homeButton.width = 30
      homeButton.icon.path = 'M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8'
      homeButton.marginBottom = 10
      homeButton.parent = chart.zoomControl
      homeButton.insertBefore(chart.zoomControl.plusButton)

      this.chart = chart
    },
  },
}
</script>

<style scoped>
.map-chart {
  width: 100%;
  height: 510px;
}
</style>
